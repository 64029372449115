"use client";

import { useEffect, useRef, ReactNode } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useScrollDirection } from "@/hooks/useScrollDirection";

interface ScrollAnimatedSectionProps {
  children: ReactNode;
  className?: string;
}

interface ScrollAnimationProps {
  children: ReactNode;
  className?: string;
  scrollDirection?: "up" | "down";
}

// Scroll animation component wrapper
const ScrollAnimatedSection: React.FC<ScrollAnimatedSectionProps> = ({
  children,
  className = "",
}) => {
  const scrollDirection = useScrollDirection(100);
  return (
    <ScrollAnimation className={className} scrollDirection={scrollDirection}>
      {children}
    </ScrollAnimation>
  );
};

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({
  children,
  scrollDirection,
  className = "",
}) => {
  const controls = useAnimation();
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, {
    once: false,
    amount: 0.1,
  });

  // Control animation based on visibility and scroll direction
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start(scrollDirection === "down" ? "hiddenUp" : "hiddenDown");
    }
  }, [controls, inView, scrollDirection]);

  return (
    <motion.div
      ref={ref}
      initial={scrollDirection === "up" ? "hiddenDown" : "hiddenUp"}
      animate={controls}
      variants={{
        hiddenUp: {
          opacity: 0,
          y: 50,
        },
        hiddenDown: {
          opacity: 0,
          y: -50,
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut",
          },
        },
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimatedSection;
