"use client";
import { FC, useEffect, useState } from "react";
import { GameDescription } from "@/app/[locale]/(dashboard)/components/GameDescription";
import { ChessGamePreview } from "@/types";

interface SampleGameProps {
  games: ChessGamePreview[];
}

export const SampleGames: FC<SampleGameProps> = ({ games }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return false;
  }

  return (
    <div className="flex flex-col gap-4 xl:grid xl:grid-cols-2">
      {games.map((x) => (
        <GameDescription
          game={x}
          user={null}
          handleSelectOpening={() => {}}
          collections={[]}
          setCollections={() => {}}
          isOnLanding
        />
      ))}
    </div>
  );
};
