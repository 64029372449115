"use client";
import React from "react";
import { Container } from "@/components/landing/Container";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerChildren } from "@/lib/animation/variants";

export const Faq = () => {
  const { t } = useTranslation();

  const faqData = [
    {
      question: t("faq.faqData.0.question"),
      answer: t("faq.faqData.0.answer"),
    },
    {
      question: t("faq.faqData.1.question"),
      answer: t("faq.faqData.1.answer"),
    },
    {
      question: t("faq.faqData.2.question"),
      answer: t("faq.faqData.2.answer"),
    },
    {
      question: t("faq.faqData.3.question"),
      answer: t("faq.faqData.3.answer"),
    },
  ];

  return (
    <div className="!p-0 mt-16">
      <motion.div
        className="w-full flex flex-col items-center "
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        variants={staggerChildren}
      >
        {faqData.map((item, index) => (
          <motion.div
            key={item.question}
            className="mb-5 w-full max-w-[1196px]"
            variants={staggerChildren}
            whileHover={{ x: 5 }}
          >
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="p-[17px] w-full bg-white rounded-lg shadow-custom border border-slate-200 text-slate-800 text-sm font-semibold leading-tight flex flex-col items-start gap-2.5 max-w-[1196px]">
                    <div className="flex justify-between self-stretch">
                      <span>{item.question}</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "transform rotate-180" : ""
                        } w-5 h-5 text-indigo-500`}
                      />
                    </div>
                    <DisclosurePanel className="bg-white text-slate-600 text-sm font-normal leading-tight text-justify">
                      {item.answer}
                    </DisclosurePanel>
                  </DisclosureButton>
                </>
              )}
            </Disclosure>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};
