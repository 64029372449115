import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/(dashboard)/pricing/components/PricingTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/animation/Motion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/animation/ScrollAnimatedSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/vercel/path0/components/landing/Faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SampleGames"] */ "/vercel/path0/components/landing/SampleGames.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useConfig"] */ "/vercel/path0/components/providers/config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/i18n/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
