const PriceCardSkeleton = ({ isThirdItem = false }) => {
  return (
    <div
      className={`relative w-full px-6 py-8 bg-white rounded-2xl shadow-lg border-2 border-slate-200 flex-col justify-center items-center gap-16 inline-flex
          ${
            !isThirdItem
              ? "col-span-12 md:col-span-6 xl:col-span-4"
              : "col-span-12 md:col-start-4 md:col-end-10 xl:col-span-4"
          }`}
    >
      <div className="self-stretch flex-col justify-start items-start gap-8 2xl:gap-12 flex">
        {/* Title and banner section */}
        <div className="self-stretch h-16 flex-col justify-start items-center gap-2 flex">
          <div className="self-stretch justify-between items-start inline-flex">
            {/* Plan title */}
            <div className="w-32 h-9 bg-slate-200 rounded animate-pulse" />
            {/* Banner */}
            <div className="w-24 h-6 bg-slate-200 rounded animate-pulse" />
          </div>
          {/* Tagline */}
          <div className="w-full h-4 bg-slate-200 rounded animate-pulse" />
        </div>

        {/* Pricing section */}
        <div className="self-stretch h-max flex-col justify-start items-start gap-4 flex">
          <div className="space-y-4">
            {/* Price */}
            <div className="w-24 h-10 bg-slate-200 rounded animate-pulse" />
            {/* Submissions text */}
            <div className="w-48 h-5 bg-slate-200 rounded animate-pulse" />
            {/* Imports text */}
            <div className="w-40 h-5 bg-slate-200 rounded animate-pulse" />
          </div>
        </div>
      </div>

      {/* Features section */}
      <div className="self-stretch h-36 mt-auto pb-6 pt-6 border-t border-slate-300 flex-col justify-start items-start gap-2 flex">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="flex gap-2 justify-start self-stretch w-full">
            {/* Checkmark */}
            <div className="w-6 h-6 bg-slate-200 rounded animate-pulse" />
            {/* Feature text */}
            <div className="flex-1 h-6 bg-slate-200 rounded animate-pulse" />
          </div>
        ))}
      </div>

      {/* Footer/Button */}
      <div className="self-stretch h-[42px]">
        <div className="w-full h-full bg-slate-200 rounded-lg animate-pulse" />
      </div>
    </div>
  );
};

export default PriceCardSkeleton;
