"use client";

import { motion, MotionProps } from "framer-motion";
import { ElementType, PropsWithChildren } from "react";

type MotionComponentProps = PropsWithChildren<{
  type?: ElementType;
  className?: string;
}> &
  MotionProps;

// Client side wrapper to use motion components in server components
const Motion = ({
  type: Component = "div",
  className,
  children,
  ...props
}: MotionComponentProps) => {
  const MotionComponent = motion.create(Component);

  return (
    <MotionComponent className={className} {...props}>
      {children}
    </MotionComponent>
  );
};

export default Motion;
