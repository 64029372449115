"use client";
import { logger } from "@/lib/logging/logger";
import { ClientUser } from "@/types";
import React, { useState } from "react";
import { getUserSubscriptionPlan } from "@/lib/server/pricing/stripe";
import { TooltipProvider } from "@/components/ui/tooltip";
import { PlatinumPlanCard } from "./PlatinumPriceCard";
import { DiamondPlanCard } from "./DiamondPlanCard";
import { GoldPlanCard } from "./GoldPlanCard";
import { PossiblePlanKeys } from "@/lib/shared/pricing/utils";
import { usePricingItemsStore } from "../../stores/pricingItemsStore";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PriceCardSkeleton from "./PriceCardSkeleton";

type PricingTableType = {
  user: ClientUser | null;
  subscriptionPlan: Awaited<ReturnType<typeof getUserSubscriptionPlan>>;
  planSubscribedTo: PossiblePlanKeys;
  hideHeaders?: boolean;
  landingPageVariant?: boolean;
};

export default function PricingTable({
  user,
  subscriptionPlan,
  planSubscribedTo,
  landingPageVariant = false,
}: PricingTableType) {
  const { t } = useTranslation(["pricing"]);
  const { pricingItems } = usePricingItemsStore();

  const [isMonthly, setIsMonthly] = useState(
    !["goldPlanYearly", "diamondPlanYearly", "platinumPlanYearly"].includes(
      planSubscribedTo,
    ),
  );
  logger.info("isMonthly", isMonthly);

  const renderDetails = () => {
    return (
      <div className="col-span-12 w-full text-justifytext-slate-700 text-sm">
        <div className="flex w-full">
          <div>{t("yearlySubscriptionDisclaimer")}</div>
        </div>
        <div className="flex w-full">
          <div>{t("monthlyUpgradeDisclaimer")}</div>
        </div>
        <div className="flex w-full">
          <div>{t("importedGamesDisclaimer")}</div>
        </div>
      </div>
    );
  };

  const billing = isMonthly ? "monthly" : "yearly";

  const monthlySwitch = () => {
    const style = clsx(
      "",
      isMonthly ? "bg-brand-secondary text-white" : "bg-white text-slate-400",
    );

    const reverseStyle = clsx(
      "",
      isMonthly ? "bg-white text-slate-400" : "bg-brand-secondary text-white",
    );

    return (
      <div className="w-full h-[50px] rounded-lg border border-slate-200 overflow-hidden justify-start items-center border-separate flex ">
        <div
          className={` grow  basis-0 h-[50px] flex-col justify-center items-center inline-flex hover:cursor-pointer ${style}`}
          onClick={() => setIsMonthly(true)}
        >
          <div className=" pt-4 pb-3.5 justify-center items-center inline-flex">
            <div className=" text-sm font-semibold leading-tight">
              {t("monthly")}
            </div>
          </div>
        </div>
        <div
          className={` grow  basis-0 h-[50px] pt-px flex-col justify-center items-center inline-flex hover:cursor-pointer ${reverseStyle}`}
          onClick={() => setIsMonthly(false)}
        >
          <div className="pt-4 pb-3.5 justify-center items-center inline-flex">
            <div className=" text-sm font-normal leading-tight">
              {t("yearly")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-8 sm:gap-16 mt-8 mb-14 sm:mb-28">
        <div>
          <div className="text-center text-slate-800 text-2xl sm:text-4xl font-bold leading-10">
            {t("title")}
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="px-8 lg:px-1 w-2/3 lg:w-1/3 flex mx-auto ">
            {monthlySwitch()}
          </div>
          <div
            className={clsx(
              landingPageVariant
                ? "px-0 grid grid-cols-12 gap-4"
                : "px-4 2xl:px-0 grid grid-cols-12 gap-4",
            )}
          >
            {pricingItems ? (
              <TooltipProvider>
                <GoldPlanCard
                  activeSubscriptionPlan={subscriptionPlan}
                  billing={billing}
                  user={!!user}
                  pricingItems={pricingItems}
                />
                <PlatinumPlanCard
                  activeSubscriptionPlan={subscriptionPlan}
                  billing={billing}
                  user={!!user}
                  pricingItems={pricingItems}
                />
                <DiamondPlanCard
                  activeSubscriptionPlan={subscriptionPlan}
                  billing={billing}
                  user={!!user}
                  pricingItems={pricingItems}
                />
                {renderDetails()}
              </TooltipProvider>
            ) : (
              <TooltipProvider>
                <PriceCardSkeleton />
                <PriceCardSkeleton />
                <PriceCardSkeleton isThirdItem />
              </TooltipProvider>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
